@mixin event-view-theme() {
  .event-view {
    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      &__default-btn {
        color: $main-color !important;
        font-weight: 600;
        // padding-right: 30px;
        // padding-left: 30px;
      }
      &__default-btn2 {
        color: #fff !important;
        background-color: $main-color;
        font-weight: 600;
        padding-right: 30px;
        padding-left: 30px;
      }
    }
    padding: 0 2rem;
    &__description {
    }
    .event-details {
      width: 100%;
      border-radius: 10px;
      .event-logo {
        width: 100%;
      }
      main {
        width: 100%;
        .id {
          font-size: 14px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          color: $gray-color;
          font-weight: 700;
          span {
            font-weight: bold;
            color: $black-color;
          }
        }

        .top-event {
          width: 100%;
        }

        .event-status {
          color: $gray-color;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          width: 100%;
          font-weight: 700;
          // span {
          //   background: rgba(76, 110, 92, 0.1);
          //   padding: 10px 13px;
          //   border-radius: 10px;
          //   color: $main-color;
          //   font-weight: bold;
          // }
        }
      }
      &__details {
        background-color: $white-color;
        padding: 20px;
        border-radius: 20px;
        img {
          width: 114px;
          height: 114px;
          object-fit: cover;
        }
      }
    }

    .viewDays {
      border-radius: 20px;

      &__details {
        background-color: $white-color;
        padding: 20px;
        border-radius: 20px;
        height: 100%;
      }
      .event-dates {
        border: 1px solid #d6d6d6;
        border-radius: 10px;
        padding: 20px;
      }
    }

    .ant-input-disabled,
    .ant-picker-disabled {
      background-color: $white-color !important;
    }
  }

  .custom-row {
    .ant-form-item-label {
      width: 100%;
      display: contents;
    }
  }

  .gray {
    color: $gray-color;
  }
}
