@mixin tabs-theme() {
  .tabs {
    ul {
      width: 100%;
      list-style: none;
      background-color: $white-color;
      border-radius: 20px;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      li {
        display: inline-block;
        margin: 0;
        padding: 10px 1.5rem;
        cursor: pointer;
        border-radius: 10px;
        color: $black-color;
        font-weight: bold;
        font-size: 15px;
        text-align: center;
      }
      .active-tab {
        background-color: $main-color;
        color: $white-color;
      }
    }
  }
}
