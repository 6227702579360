@mixin table-header-theme() {
  .tableHeader {
    width: 100%;
    background-color: $white-color;
    padding: 10px 30px;
    margin: 3rem 0;
    border-radius: 10px;
  }

  .search {
    &__input {
      background-color: $background-color !important;
      border: none;
      outline: none;
    }
    .ant-input {
      background-color: transparent;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    &__add-event {
      background-color: $main-color;
      border: 1px solid $main-color;
      padding: 5px 20px;
      height: 40px;
      &:hover {
        background-color: transparent !important;
        border: 1px solid $main-color-hover;
        color: $main-color !important;
      }
    }

    &__filter {
      padding: 5px 20px;
      height: 40px;
      border: 1px solid $main-color;
      &:hover {
        border: 1px solid $main-color !important;
        // color: $white-color !important; // Hidden by management
        background-color: $main-color;
      }
    }
    &__Reset{
      padding: 5px 20px;
      height: 40px;
      border: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
