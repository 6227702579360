@mixin dashboard() {
  .dashboard-content {
    width: 100%;
    padding: 40px;
    h1 {
      font-size: 20px;
    }
    &__total {
      width: 100%;
      border-radius: 10px;
      color: $white-color;
      padding: 20px;
      background-color: $white-color;
      p {
        margin: 0;
        padding: 0;
        font-size: 16px;
      }
      span {
        margin-top: 1rem;
        display: block;
        font-weight: bold;
      }
      .img {
        background-color: #4c6e5c;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: 0;
        object-fit: scale-down;
      }
      div {
        p {
          color: #686767;
          margin: 0;
          padding: 0;
        }
        span {
          margin: 0;
          padding: 0;
          color: #252525;
          font-weight: bold;
          margin-top: 10px;
        }
      }
    }
  }
  .green-bk {
    background-color: $main-color;
    color: #fff !important;
    p {
      color: #fff !important;
    }
    span {
      color: #fff !important;
    }
    .img {
      background-color: #fff;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      padding: 0;
      object-fit: scale-down;
    }
  }
}

.mt-2 {
  margin-top: 2rem;
}
