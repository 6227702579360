$main-color: #4c6e5c;
$main-color-hover: #1a7b72;
$gray-color: #a6a6a6;
$white-color: #fff;
$black-color: #252525;
$background-color: #f7f8f7;
$span-color: #686767;
$light-background: #f7f8f7;
$border-color: #e2e3e2;
$light-gray: #e1e1e1;
$dark-gray: #575656;
$form-background: #f7f8f7;
@import "../../modules/modules.scss";
@import "../../components/nav-bar/nav-bar.scss";
@import "../../components/header/header.scss";
@import "../../components/table-search/tableSearch.scss";
@import "../../modules/events/events-theme.scss";
@import "../../components/customTable/customTable.scss";
@import "../../modules/events/components/stepper/stepper.scss";
@import "../../components/tabs/tabs.scss";
@import "../../modules/events/event-view/event-view.scss";
@import "../../modules/ushers/ushers-theme.scss";
@import "../../authentication/authentication-theme.scss";
@import "../../modules/hiring-ushers/hiring-ushers-theme.scss";
@import "../../components/questions/questions.scss";
@import "../../modules/profile/profile-theme.scss";
@import "../../modules/leaders/leader-theme.scss";
@import "../../components/unassign-confirm/unAssign.scss";
@import "./spineer.scss";
@import "../../modules/transactions/transaction-theme.scss";
@import "../../modules/categories/categories-theme.scss";
@import "../../modules//UsherTypes/usherType-theme.scss";
@import "../../modules/dashboard/dashboard.scss";

@mixin ushers-theme() {
  @include content-theme();
  @include navBar();
  @include header-theme();
  @include table-header-theme();
  @include events-theme();
  @include custom-table-theme();
  @include stepper-theme();
  @include tabs-theme();
  @include event-view-theme();
  @include ushers-module-theme();
  @include auth-theme();
  @include Hiring-Ushers-Theme();
  @include question-theme();
  @include profile-theme();
  @include leader-theme();
  @include unassign-confirm-theme();
  @include transaction-module-theme();
  @include categories-theme();
  @include ushsertype-theme();
  @include dashboard();
}

@include ushers-theme();
