@mixin stepper-theme() {
  .stepper {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    background-color: $white-color;
    border-bottom: 1px solid $light-gray;
    gap: 4rem;
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      &__number {
        background-color: $light-gray;
        color: $gray-color;
        padding: 7px 11px;
        border-radius: 50%;
        font-size: 15px;
        font-weight: 500;
      }
      &__title {
        font-size: 18px;
        color: $gray-color;
      }
    }
    .active-item {
      color: #252525;
      font-weight: bold;
    }
    .active-number {
      background-color: $main-color;
      color: $white-color;
    }
  }
}
