@mixin navBar() {
  .nav {
    background-color: $white-color;
    border-radius: 0px, 20px, 20px, 0px;
    padding: 40px 0;
    position: relative;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 280px;
    overflow: auto;
    img {
      display: block;
      margin: 30px auto;
    }

    .menu {
      margin-top: 3rem;
      position: relative;
      .item {
        margin-top: 10px;
        color: $gray-color;
        font-weight: bold;
        &:hover {
          border-right: 2px solid $main-color;
          border-radius: none;
        }
      }

      .absolute_bottom {
        position: absolute;
        bottom: 0;
      }

      .ant-menu-item-active {
        background-color: transparent !important;
        border-radius: 0 !important;
        border-right: 2px solid $main-color;
      }

      .ant-menu-item-selected {
        background-color: transparent;
        border-right: 2px solid $main-color;
        border-radius: 0;
        color: $black-color;
        font-weight: bold;
      }
    }

    .logout {
      position: absolute;
      padding-bottom: 1.5rem;
      left: 1.5rem;
      color: $gray-color;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      &:hover {
        color: $black-color;
      }
      img {
        display: inline-block;
        margin: 0;
      }
    }
  }
}
