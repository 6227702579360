@mixin header-theme() {
  header {
    width: 100%;
    margin-top: 3rem;
    padding: 0 3rem;
    .profile {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 5px 20px;
      gap: 15px;
      border-radius: 30px;
      background: $white-color;
      box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
      &__notification-img {
        margin-top: 0.7rem;
      }
      &__user {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 15px;
        background: $light-background;
        border-radius: 30px;
        padding: 0 13px;
        img {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          object-fit: contain;
        }
        p {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    .breadcrumb {
      background-color: transparent;
      ol {
        li {
          span {
            color: $span-color;
            font-size: 16px;
          }
        }
      }
    }
  }
}
