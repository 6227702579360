@mixin content-theme() {
  .content {
    display: block;
    .layout {
      background-color: #f7f8f7;
    }
    .ant-layout {
      background-color: $background-color !important;
    }
  }
}
