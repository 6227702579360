@mixin profile-theme() {
  .profile {
    width: 100%;
    height: 100%;
    padding: 1rem 3rem;
    &__card {
      padding: 20px;
      height: 100px;
      width: 100%;
      background-color: $main-color;
      border-radius: 20px;
      main {
        img {
          border-radius: 50%;
          height: 150px;
          width: 150px;
          object-fit: contain;
        }
        h1 {
          margin-top: 4rem;
        }
      }
    }

    &__form {
      margin-top: 10rem;
      .ant-form-item-label {
        label {
          font-weight: 16px !important;
          color: #afbaca !important;
        }
      }
      .ant-input-disabled {
        background-color: $white-color !important;
        color: #afbaca !important;
      }
    }
  }
}
