@mixin ushers-module-theme() {
  .usherList {
    width: 100%;
    padding: 0 2rem;
    .ant-table-thead {
      .ant-table-cell {
        color: #c4c4c4;
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        background-color: #fff;
      }

      .ant-table-cell-row-hover {
        background: #dcf0ee !important;
      }
    }
  }
}
