html,
body {
  height: 100%;
  min-height: -webkit-fill-available;
  background-color: #f7f8f7;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-btn-primary {
  box-shadow: none;
}

.mb-4 {
  margin-bottom: 3rem;
}

.mt-1 {
  margin-top: 1rem;
}

.w-100 {
  width: 100% !important;
}

.ptr {
  cursor: pointer;
}

.mt-4 {
  margin-top: 3rem;
}

.mt-1_5 {
  margin-top: 1.5rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-5px {
  margin-bottom: 5px;
  display: block;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex {
  display: flex;
  gap: 5px;
  justify-content: flex-start !important;
}

.felx-start {
  display: flex;
  justify-content: start;
  align-items: center;
}

.ant-input-disabled {
  color: #252525 !important;
}

.ant-picker .ant-picker-input > input[disabled] {
  color: #252525;
}

.gap-10 {
  gap: 10px;
}

.mb-1-5 {
  margin-bottom: 1.5rem;
}

.disabled-input {
  background-color: #fff !important;
  border: 1px solid #d7dfe9 !important;
}

.text-center {
  text-align: center;
}

.ant-breadcrumb a {
  text-decoration: underline;
}

.mt-2 {
  margin-top: 2rem;
}

.mlr-1 {
  margin: 1rem;
}

.dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(204, 204, 204, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999999999999;
}

.ant-breadcrumb-link,
.ant-breadcrumb-separator {
  font-size: 24px;
}

.w-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.mb-2 {
  margin-bottom: 2rem;
}

.d-block {
  display: block;
}

.mb-1 {
  margin-bottom: 1rem;
}
