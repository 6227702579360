@mixin Hiring-Ushers-Theme() {
  .hiring-ushers {
    padding: 1rem 3rem;
  }

  .hiring-info {
    &__content {
      background-color: $white-color;
      padding: 1rem;
      border-radius: 10px;
    }
    .img-container {
      width: 100%;
      height: 200px;
      border-radius: 20px;
      background-color: #fcf9f9;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
    }

    .p {
      font-size: 15px;
      color: $gray-color;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.2px;
      span {
        color: $black-color;
        font-weight: bold;
      }
      .status {
        background-color: $light-gray;
        color: $main-color;
        padding: 10px 40px;
        border-radius: 5px;
      }
    }

    .vaild-text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.2px;
      color: #28c76f;
    }
  }

  .hiring-process {
    background-color: $white-color;
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    .note-item {
      width: 100%;
      border: 2px solid #e1e1e1;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 10px;
      img {
        width: 100%;
        border-radius: 50%;
      }
      main {
        padding: 10px;
        h1 {
          margin: 0;
          padding: 0;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: -0.5px;
        }

        p {
          margin: 0;
          padding: 0;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.2px;
          color: $gray-color;
        }
      }

      .description {
        margin: 0;
        padding: 0 10px;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.3px;
      }
    }
  }
}
