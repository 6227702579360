@mixin events-theme() {
  .eventList {
    width: 100%;
    padding: 0 2rem;
    overflow-x: scroll;
    .ant-table-thead {
      .ant-table-cell {
        color: #c4c4c4;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        background-color: #fff;
      }

      .ant-table-cell-row-hover {
        background: #dcf0ee !important;
      }
    }
  }

  .action-item {
    display: flex;
    gap: 10px;
    font-weight: 500;
    border-bottom: 1px solid $border-color;
    padding: 5px 0;
    margin: 0;
  }

  .anchor-btn {
    background-color: transparent;
    border: none;
  }

  .event-form {
    width: 95%;
    margin: 20px auto;
    background-color: $white-color;
    height: 100%;
    .ant-steps-item-active {
      border: none !important;
      color: $main-color !important;
    }
  }

  .eventDetails {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 1rem 4rem;
    &__file {
      padding: 20px;
      width: 25%;
      height: 100%;
    }
    &__inputs {
      padding: 20px;
      width: 75%;
      label {
        font-size: 15px !important;
        color: $dark-gray !important;
      }
      .slider-event {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        margin-top: 2.2rem;
        .title {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          margin-top: 2px;
        }
      }
    }
  }
  .custom-input {
    border-color: $dark-gray;
  }

  .submit-btn {
    width: 100%;
    padding: 14px 18px;
    height: inherit;
    transition: all 0.5s;
    box-shadow: none;
    &:hover {
      background-color: $white-color !important;
      color: $main-color !important;
      border-color: $main-color;
    }
  }

  .cancel-btn {
    width: 100%;
    padding: 14px 18px;
    height: inherit;
    margin: 10px 0;
    border-color: $main-color;
    transition: all 0.5s;
    &:hover {
      background-color: $main-color;
      color: $white-color !important;
    }
  }

  .days {
    padding: 1rem 6rem;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    &__form {
      width: 100%;
    }
    .event-dates {
      width: 100%;
      border: 1px solid $dark-gray;
      border-radius: 10px;
      padding: 20px;
    }
  }

  .usherDetaails {
    padding: 1rem 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &__form {
      width: 100%;
    }
    &__data {
      padding: 20px;
      border: 1px solid $main-color;
      border-radius: 10px;
    }
  }

  .w-100 {
    width: 100%;
  }
  .ant-input-number-prefix,
  .ant-input-number-suffix {
    pointer-events: all !important;
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .event-questions {
    width: 100%;
    padding: 20px 6rem;
    &__question {
      width: 100%;
      padding: 30px 0;
    }
    &__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    &__question {
      &__options {
        background-color: $background-color;
        border: 1px solid $light-gray;
        box-shadow: 0px 4px 14px 0px rgba(38, 38, 38, 0.08);
        padding: 10px 10px;
        border-radius: 30px;
        margin-top: 1rem;
        .ant-segmented-item-selected {
          background-color: $main-color;
          color: $white-color;
          border-radius: 20px !important;
        }
      }
    }
    .add-choise {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 2rem;
      gap: 10px;
      font-size: 15px;
      background-color: $background-color;
      border: none;
      border-radius: 10px;
      color: $main-color;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        background-color: $light-gray;
      }
    }
    .splitting-line {
      margin: 0 0;
    }
  }

  .event-icon {
    background-color: $light-gray;
    border-radius: 50%;
  }
  
  .red {
    color: red;
  }
}
