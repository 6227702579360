@mixin leader-theme() {
  .leader-form {
    width: 100%;
    padding: 2rem 2rem;
    .btn {
      height: 45px;
    }
  }

  .leader-view {
    padding: 2rem 2rem;

    .leader-info {
      &__profile {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        p {
          color: $gray-color;
          font-weight: 600;
          display: flex;
          span {
            color: $black-color;
            font-weight: bold;
          }
          .activeStatus {
            background-color: $light-gray;
            color: green;
            padding: 5px 15px;
            border-radius: 5px;
          }
        }
        &__logo {
          width: 100%;
          height: 200px;
          background-color: #e1e1e1;
          border: 3px dashed #ccc;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .vaild-text {
          color: #28c76f;
          font-size: 12px;
        }
      }

      &__assignEvent {
        background-color: #fff;
        border-radius: 10px;
        padding: 10px 0;
        h1 {
          margin: 0;
          padding: 0;
          width: 100%;
          font-size: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $light-gray;
          padding: 5px 20px;
          span {
            font-size: 14px;
          }
        }
        &__eventDetails {
          padding: 10px 20px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          div {
            h2 {
              .event-status {
                background: rgba(76, 110, 92, 0.1);
                color: #4c6e5c;
                font-size: 12px;
                margin: 0 10px;
                padding: 5px;
                border-radius: 5px;
              }
            }

            p {
              color: #252525;
              font-weight: bold;
            }

            .assign-date {
              color: $gray-color;
            }
          }
        }

        &__NoAssign {
          padding: 10px;
          label {
            color: #979797;
          }
          div {
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 10px;
            margin: 10px 0;
          }
        }
      }
    }
  }

  .leader-activities {
    padding: 0 3rem;
  }
}
