@mixin auth-theme() {
  .loginLogo {
    width: 100%;
    min-height: 100vh;
    background: url("../assets/img/login_BK.jpeg") no-repeat center center;
    background-size: cover;
  }

  .auth-form {
    width: 70%;
    min-height: 100vh;
    background-color: $form-background;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin: 0 auto !important;
    .login-btn {
      height: 45px !important;
    }
  }
}
