@mixin question-theme() {
  .questions {
    background-color: $white-color;
    padding: 0.5rem;
    border-radius: 10px;
    height: 100%;
    h1 {
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $light-gray;
      margin-bottom: 10px;
      padding-bottom: 10px;
      .title {
        width: 70%;
      }
      .number {
        width: 30%;
        text-align: center;
        background: rgba(225, 225, 225, 0.3);
        font-size: 14px;
        padding: 5px;
      }
    }
    .ant-collapse-header-text {
      color: #686767;
    }

    .ant-collapse-content-box {
      p {
        margin: 0;
        padding: 0;
        color: $black-color;
        font-weight: bold;
      }
    }
  }
}
